import React, { useState } from 'react'
import FAQ from './FAQ'

const FAQsPage = () => {

    const [faqs, setFaqs] = useState([
        {
            box:"01",
            question: "What services does Tapas provide?",
            answer:
                "At Tapas, we specialize in design, development, and project management services. Our expertise includes user experience design, web development, mobile app development, custom software development, branding, and identity solutions.",
            open: true
        },
        {
            box:"02",
            question: "How can Tapas help my business?",
            answer: "Tapas transforms businesses through tailored digital solutions, from captivating websites and custom software to strategic marketing, ensuring sustained success with continuous support.",
            open: false
        },
        {
            box:"03",
            question:
                "What industries does Tapas work with?",
            answer: "Tapas operates across various industries including healthcare, retail and e-commerce, finance and banking, education, hospitality and tourism, automotive, entertainment and media, textile and virtual reality.",
            open: false
        },
        {
            box:"04",
            question:
                "How long does it take to complete a project with Tapas?",
            answer: "The time to complete a project with Tapas varies depending on its complexity and requirements. We work closely with clients to establish realistic timelines and strive to deliver high-quality results efficiently.",
            open: false
        },
        {
            box:"05",
            question:
                "Do you offer ongoing support and maintenance after the project is completed?",
            answer: "Yes, Tapas provides ongoing support and maintenance services after the project is completed. We are committed to ensuring the continued success and smooth operation of your digital assets, offering assistance, updates, and troubleshooting as needed.",
            open: false
        },
        {
            box:"06",
            question:
                "Can you work with existing design or development frameworks?",
            answer: "Yes, Tapas can work with existing design or development frameworks. Whether you have specific preferences or ongoing projects that require compatibility with certain frameworks, we adapt our approach to meet your needs and seamlessly integrate with your existing systems.",
            open: false
        },
        {
            box:"07",
            question:
                "How involved will I be in the project development process?",
            answer: "Your involvement in the project development process with Tapas is customizable based on your preferences. We prioritize transparent communication and collaboration, ensuring you're informed and engaged throughout the process.",
            open: false
        },
        {
            box:"08",
            question:
                "Can you help with website or app maintenance and updates?",
            answer: "Tapas provides website and app maintenance services, ensuring your digital assets remain up-to-date and optimized for performance.",
            open: false
        },
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div className='container'>
            <div className='faq-main'>
                <div className='row'>
                    <div className='hero-faq'>
                        <h2 className='text-[48px] font-semibold tracking-wide'>Frequently Asked Questions</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Still you have any questions? Contact our Team via tapastechnologypvtltd@gmail.com</p>
                    </div>
                    <div className="faqs">
                        {faqs.map((faq, index) => (
                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQsPage
