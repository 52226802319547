import React from 'react'
import Branding from '../assests/Update-Tapas-Img/design.png'
import WebApp from '../assests/Update-Tapas-Img/web-dev.png'
import ProjectManage from '../assests/Update-Tapas-Img/project.png'
import SEOMarketing from '../assests/Update-Tapas-Img/SEO-Market.png'
import { Link } from 'react-router-dom'

const Service = () => {
    return (
        <div className='container '>
            <div className='service-main '>
                <div className='row'>
                    <div className='service-hero'>
                        <h2 className='text-[48px] font-semibold'>Our Services</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Transform your brand with our innovative digital solutions that captivate and engage your audience.</p>
                    </div>
                </div>
                <div className='service-child'>
                    <div className='row'>
                        <div className='service-part '>
                            <div className='design-brand '>
                                <div className='design-brand-head'>
                                    <img src={Branding} />
                                    <div>

                                    <h3>Designing & branding</h3>
                                    </div>
                                </div>
                                <p>We craft unique designs & tell your brand story, making you stand out. ✨</p>
                            </div> 
                            <div className='web-app '>
                                <div className='web-app-head'>
                                    <img src={WebApp} />
                                    <div>

                                    <h3>Web & App Development</h3>
                                    </div>
                                </div>
                                <p>Bringing your concepts to life: web & app development that delivers results.</p>
                            </div>
                            <div className='project-management '>
                                <div className='project-management-head'>
                                    <img src={ProjectManage} />
                                    <h3>Project Management</h3>
                                </div>
                                <p> Keeping your projects on track and on time: expert project management.</p>
                            </div>
                        </div>
                        <div className='service-part-2'>
                            <div className='SEO-marketing'>
                                <div className='SEO-marketing-head'>
                                    <img src={SEOMarketing} />
                                    <h3>SEO & Marketing</h3>
                                </div>
                                <p>Boosting visibility & engagement: Expert SEO & marketing that connects you to your audience.</p>
                            </div>
                            <div className='industrial-solution'>
                                <div className='industrial-solution-head'>
                                    <div className='industrial-solution-heading'>
                                        <h3>Industrial Solutions</h3>
                                    </div>
                                    <div className='industrial-solution-heading-btn'>
                                        <Link to={'/Industrial-Solutions'}>
                                        <button type='submit'>Learn More</button>
                                        </Link>
                                    </div>
                                  
                                </div>
                                <p>Empowering industries with advanced software solutions tailored to streamline operations and enhance productivity.
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
