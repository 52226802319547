import React from 'react'
import CEOImage from '../assests/Update-Tapas-Img/Final-CEO-image.png'
import CEOImage2 from '../assests/Update-Tapas-Img/Final-CEO-Sec-Image.png'
// import CEOImage3 from '../assests/Update-Tapas-Img/final-final-ceo-image-.svg'
// import Employee from '../Component/Employee-data.json'

const Ourteam = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div>
                    <div className='our-team-hero'>
                        <h2 className='text-[48px] font-semibold'>Our Team</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Meet the brains behind the brilliance.</p>
                    </div>
                    <div className='our-team-child'>
                        <div>
                            <div>
                                <div className='grid grid-cols-2 xs:grid-cols-1 w-[50%] md:w-[70%] xs:w-[80%] sm:w-[70%] gap-[30px] mx-auto mt-[30px]'>
                                    <div className=' xs:w-[100%] xs:mx-auto bg-[#090909] py-[50px] our-team-card-main'>
                                        <div className='w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] mx-auto'>
                                            <img src={CEOImage} alt='CEO Image' className='w-[100%] h-[100%] rounded-[50%]'></img>
                                        </div>
                                        <div className='mt-[20px]'>
                                            <p className='text-[20px] mb-[0px] text-center'>Divyesh Patel</p>
                                            <p className='mt-[0px] mb-[0px] text-center'>CEO</p>
                                        </div>
                                    </div>
                                    <div className=' xs:w-[100%] xs:mx-auto  bg-[#090909] py-[50px] our-team-card-main'>
                                        <div className='w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] mx-auto'>
                                            <img src={CEOImage2} alt='CEO Image' className='w-[100%] h-[100%] rounded-[50%]'></img>
                                        </div>
                                        <div className='mt-[20px]'>
                                            <p className='text-[20px] mb-[0px] text-center'>Chirag Moradiya</p>
                                            <p className='mt-[0px] mb-[0px] text-center'>CEO</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='grid grid-cols-4 xs:grid-cols-1 xs:w-[80%] xs:mx-auto mt-[30px] gap-[30px] w-[100%]'>
                                    {
                                        Employee.map((e) => (
                                            <div className=' xs:w-[100%] xs:mx-auto bg-[#090909] py-[50px] our-team-card-main'>
                                                <div className='w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] mx-auto'>
                                                    <img src={e.employeeimage} alt='Employee Image' className='w-[100%] h-[100%] rounded-[50%]'></img>
                                                </div>
                                                <div className='mt-[20px]'>
                                                    <p className='text-[20px] mb-[0px] text-center'>{e.employeename}</p>
                                                    <p className='mt-[0px] mb-[0px] text-center'>{e.role}</p>
                                                </div>
                                            </div>
                                            
                                        ))
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ourteam
