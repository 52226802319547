import React from 'react'
import Heathcare from '../assests/Update-Tapas-Img/animte-heathcare.gif'
import Bank from '../assests/Update-Tapas-Img/anime-bank.gif'
import Book from '../assests/Update-Tapas-Img/anime-book.gif'
import Ecommerce from '../assests/Update-Tapas-Img/ecommerce.gif'
import Tourism from '../assests/Update-Tapas-Img/anima-hospitality.gif'
import Automobileicon from '../assests/Update-Tapas-Img/anime-automobile.gif'
import Entertaiment from '../assests/Update-Tapas-Img/media.gif'
import Textileicon from '../assests/Update-Tapas-Img/textile.gif'
import Vertualicon from '../assests/Update-Tapas-Img/virtual.gif'

const Industripage = () => {
    return (
        <div className='container'>
            <div className='industries-page-main'>
                <div className='row'>
                    <div className='industries-hero'>
                        <h2 className='text-[48px] font-semibold'>Industries in which we excel</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Our expertise extends to the Multiple industry.</p>
                    </div>
                    <div className='industries-page-child'>
                        <div className='industries-service-part-first'>
                            <div className='healthcare'>
                                <div className='heathcare-icon'>
                                    <img src={Heathcare} alt=''/>
                                </div>
                                <div className='heathcare-heading'>
                                    <h3>Healthcare</h3>
                                </div>
                            </div>
                            <div className='bank'>
                                <div className='bank-icon'>
                                    <img src={Bank} alt='' />
                                </div>
                                <div className='bank-heading'>
                                    <h3>Finance & Banking</h3>
                                </div>
                            </div>
                            <div className='education'>
                                <div className='education-icon'>
                                    <img src={Book} alt='' />
                                </div>
                                <div className='education-heading'>
                                    <h3>Education</h3>
                                </div>
                            </div>
                            <div className='retail'>
                                <div className='retail-icon'>
                                    <img src={Ecommerce} alt='' />
                                </div>
                                <div className='retail-heading'>
                                    <h3>Retail and E-commerce</h3>
                                </div>
                            </div>
                            <div className='tourism'>
                                <div className='tourism-icon'>
                                    <img src={Tourism} alt='' />
                                </div>
                                <div className='tourism-heading'>
                                    <h3>Hospitality and Tourism</h3>
                                </div>
                            </div>
                            <div className='automobile'>
                                <div className='automobile-icon'>
                                    <img src={Automobileicon} alt='' />
                                </div>
                                <div className='automobile-heading'>
                                    <h3>Automotive</h3>
                                </div>
                            </div>
                            <div className='industree-media'>
                                <div className='media-icon'>
                                    <img src={Entertaiment} alt='' />
                                </div>
                                <div className='media-heading'>
                                    <h3>Entertainment and Media</h3>
                                </div>
                            </div>
                            <div className='textile'>
                                <div className='textile-icon'>
                                    <img src={Textileicon} alt='' />
                                </div>
                                <div className='textile-heading'>
                                    <h3>Textile</h3>
                                </div>
                            </div>
                            <div className='virtual'>
                                <div className='virtual-icon'>
                                    <img src={Vertualicon} alt='' />
                                </div>
                                <div className='virtual-heading'>
                                    <h3>Virtual Reality</h3>
                                </div>
                            </div>
                        </div>

                        {/* <div className='industrial-service-part-second'>
                            <div className='retail'>
                                <div className='retail-icon'>
                                    <img src={Ecommerce} alt='' />
                                </div>
                                <div className='retail-heading'>
                                    <h3>Retail and E-commerce</h3>
                                </div>
                            </div>
                            <div className='tourism'>
                                <div className='tourism-icon'>
                                    <img src={Tourism} alt='' />
                                </div>
                                <div className='tourism-heading'>
                                    <h3>Hospitality and Tourism</h3>
                                </div>
                            </div>
                            <div className='automobile'>
                                <div className='automobile-icon'>
                                    <img src={Automobileicon} alt='' />
                                </div>
                                <div className='automobile-heading'>
                                    <h3>Automotive</h3>
                                </div>
                            </div>
                            <div className='industree-media'>
                                <div className='media-icon'>
                                    <img src={Entertaiment} alt='' />
                                </div>
                                <div className='media-heading'>
                                    <h3>Entertainment and Media</h3>
                                </div>
                            </div>
                            <div className='textile'>
                                <div className='textile-icon'>
                                    <img src={Textileicon} alt='' />
                                </div>
                                <div className='textile-heading'>
                                    <h3>Textile</h3>
                                </div>
                            </div>
                            <div className='virtual'>
                                <div className='virtual-icon'>
                                    <img src={Vertualicon} alt='' />
                                </div>
                                <div className='virtual-heading'>
                                    <h3>Virtual Reality</h3>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className='industrial-service-part-third'>
                            <div className='industree-media'>
                                <div className='media-icon'>
                                    <img src={Entertaiment} alt='' />
                                </div>
                                <div className='media-heading'>
                                    <h3>Entertainment and Media</h3>
                                </div>
                            </div>
                            <div className='textile'>
                                <div className='textile-icon'>
                                    <img src={Textileicon} alt='' />
                                </div>
                                <div className='textile-heading'>
                                    <h3>Textile</h3>
                                </div>
                            </div>
                            <div className='virtual'>
                                <div className='virtual-icon'>
                                    <img src={Vertualicon} alt='' />
                                </div>
                                <div className='virtual-heading'>
                                    <h3>Virtual Reality</h3>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Industripage
