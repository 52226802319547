import React from 'react'
import SunIcon from '../assests/Update-Tapas-Img/final-tapas-service-divider.png'

const Movetxt = () => {
    return (
        <div className='container'>
            <div className='mt-5 row'>
                <div className='home-page-ser-main'>
                    <marquee>
                        <div className='home-page-service'>
                            <div className='items-center home-page-service-first text-bold-etalic'>
                                <div className='sun_icon'>
                                    {/* <img src={SunIcon} alt='' /> */}
                                </div>
                                <div className='branding'>
                                    <h3><b style={{fontWeight:"600"}}>BRANDING</b></h3>
                                </div>
                            </div>
                            <div className='items-center home-page-service-second text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} alt='' />
                                </div>
                                <div className='designing'>
                                    <h3><b style={{fontWeight:"600"}}>DESIGNING</b></h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-third text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} alt='' />
                                </div>
                                <div className='web-dev'>
                                    <h3><b style={{fontWeight:"600"}}>WEB</b> DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-four text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} alt='' />
                                </div>
                                <div className='app-dev'>
                                    <h3><b style={{fontWeight:"600"}}>ANDROID</b> APP DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} alt='' />
                                </div>
                                <div className='ios-app-dev'>
                                    <h3><b style={{fontWeight:"600"}}>IOS</b> APP DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} alt=''/>
                                </div>
                                <div className='blockchain-dev'>
                                    <h3><b style={{fontWeight:"600"}}>BLOCKCHAIN</b> DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='adverties'>
                                    <h3><b style={{fontWeight:"600"}}>SEO</b> AND <b style={{fontWeight:"600"}}>ADVERTIES</b></h3>
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>
            </div>
        </div>
    )
}

export default Movetxt 
