import React,{useEffect} from 'react';
import AnimateTapas from '../assests/Update-Tapas-Img/abou-us-animation.png';
import CountUp from "react-countup";

import MainAnime from '../assests/Update-Tapas-Img/final-full-page-back-anime.gif';
const dataArray = [
    { id: 1,image: '../assests/Update-Tapas-Img/abou-us-animation.png', title: 'Discovery', content: 'We begin by thoroughly understanding your business goals, target audience, and project requirements. We conduct in-depth research to gather insights and define project objectives, allowing us to develop a tailored strategy.' },
    { id: 2, title: 'Planning and Strategy', content: 'Based on the gathered information, we create a comprehensive project plan and strategy. This includes defining project milestones, timelines, deliverables, and resource allocation. We collaborate closely with you to align our strategy with your vision.' },
    { id: 3, title: 'Design', content: 'Our expert designers translate the project requirements into captivating visual designs. We create wireframes, mockups, and interactive prototypes to showcase the user interface, user experience, and overall design aesthetics. We iterate on the designs based on your feedback until we achieve the perfect look and feel.' },
    { id: 4, title: 'Development', content: 'Once the designs are approved, our skilled development team brings them to life. We use cutting-edge technologies and coding best practices to build robust and scalable digital products. Throughout the development phase, we maintain open lines of communication to keep you updated on progress and address any questions or concerns.' },
    { id: 5, title: 'Testing and Quality Assurance', content: 'We conduct rigorous testing to ensure that your digital product functions flawlessly across different devices, browsers, and operating systems. Our quality assurance team meticulously checks for bugs, usability issues, and performance bottlenecks. We strive for a seamless user experience and a high level of reliability.' },
    { id: 6, title: 'Deployment and Launch', content: 'When your digital product is thoroughly tested and meets your satisfaction, we prepare for deployment. We handle all the technical aspects of launching your product, ensuring a smooth transition from development to the live environment. We assist with setting up hosting, configuring servers, and managing any required integrations.' },
    { id: 7, title: 'Post-Launch Support', content: 'When your digital product is thoroughly tested and meets your satisfaction, we prepare for deployment. We handle all the technical aspects of launching your product, ensuring a smooth transition from development to the live environment. We assist with setting up hosting, configuring servers, and managing any required integrations.' },
    { id: 8, title: 'Continuous Improvement', content: 'We believe in continuous improvement and strive to optimize your digital product even after launch. We monitor user feedback, analytics, and market trends to identify opportunities for enhancement and growth. We proactively suggest improvements and updates to keep your digital product ahead of the curve.' }
];
const AboutUs = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[]);
    return (
        <div className='container mt-[10px]'>
            <div className='flex justify-content-around  about-main-final'>
                <div className="w-[50%]  final-about-info">
                    <h1 className='text-[48px] font-semibold mb-[20px]'>
                        About Tapas
                    </h1>
                    <p className='text-[20px] text-[#E6E6E6] font-extralight font-regular leading-relaxed'>
                        Tapas is a digital product agency that is passionate about crafting exceptional digital experiences. We specialize in design, engineering, and project management, helping businesses thrive in the digital landscape. At Tapas, we follow a structured and collaborative process to ensure the successful delivery of exceptional digital products. Our process combines industry best practices, creative thinking, and a client-centric approach.
                    </p>
                </div>
                <div className='w-[50%] final-about-img'>
                    <img className='w-[100%] pl-10' src="/img/about-1.png" alt="" srcSet="" />
                </div>
            </div>
            <div className='border-[#7b7b7b4c] border-[1px] p-5 final-about-main-change'>
                <div className='w-[60%] final-about-main-hrading-part'>
                    <h1 className='text-[48px] font-semibold mb-[20px]'>At Tapas</h1>
                    <p className='text-[18px]'>
                        We follow a structured and collaborative process to ensure the successful delivery of exceptional digital products. Our process combines industry best practices, creative thinking, and a client-centric approach.</p>
                </div>
                <div>
                    <h1 className='text-[22px] mt-[40px] mb-[20px] bg-[#191919] inline-block p-2.5'>
                        Here's an overview of our typical process:
                    </h1>
                </div>
                <div className='flex flex-wrap final-about-step'>
                    {dataArray.map((item, i) => (
                        <div key={item.id} className='w-50 border-[#7b7b7b17] border-[1px] final-about-child-step'>
                            <div className='flex items-center final-about-count'>
                                {/* <img src={`/img/final-number-${i + 1}.svg`} className='final-about-count-img' alt="About us Number" srcSet="" /> */}
                                {/* <img src={`/img/final-number-${i + 1}.svg`} className='final-about-count-img' alt="About us Number" srcSet="" /> */}
                                <h1 className='text-[150px] text-[#FFA370]'>0{i + 1}</h1>
                                <h1 className=' text-[30px] text-[#98989A] final-about-hrading'>{item.title}</h1>
                            </div>
                            <div className='text-[#98989A] text-[16px] mt-[50px] final-about-content'>
                                {item.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='row'>
                <div className='about-us-main'>
                    <div className='about-us-child'>
                        <div className='about-us-hero'>
                            <h2>Discover Your Dream <br /> Property With Estatein</h2>
                            <p>Your journey to finding the perfect property begins here. Explore our listings to find the home that matches your dreams.</p>
                            <div className='about-us-button'>
                                <button className='about-service'> Our Service</button>
                                <button className='expretise'>Our Expertise</button>
                            </div>
                        </div>
                        <div className='about-us-counter d-flex justify-content-between'>
                            <div className='happy-client'>
                                <h2><CountUp end={200} duration={3} ></CountUp></h2>
                                <p>Happy Client</p>
                            </div>
                            <div className='properti-for-client'>
                                <h2><CountUp end={10000} duration={3} ></CountUp></h2>
                                <p>Properties For Clients</p>
                            </div>
                            <div className='Experince'>
                                <h2><CountUp end={16} duration={3} ></CountUp></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                    <div className='about-us-tapas-animate'>
                        <img src={AnimateTapas} alt='' />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default AboutUs
