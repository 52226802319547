import React, { useEffect, useState } from 'react'
import SunIcon from '../assests/Update-Tapas-Img/final-tapas-service-divider.png'
import ServicePage from './Service';
import Industripage from './Industripage';
import ChooseUs from './Whychooseus'
import Projects from './Projects';
import ValuatedClient from './ValuatedClient';
import FAQsPage from './FAQsPage';
import { Link } from 'react-router-dom';
import MaiImage from '../assests/Update-Tapas-Img/final-update-hero-animate.gif'
import MainAbout from './MainAbout';


// import FullAnime from '../assests/Update-Tapas-Img/final-full-page-back-anime.gif'

const Home = () => {
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);
    return (

        <div className='container mt-[10px] '>
            <div className='row'>
                <div className='hero-section-main'>
                    <div className='hero-section-child'>
                        <div className='Heading-Main col-sm-12 '>
                            <h1 className='sm:text-[34px]' >
                                A Digital Product
                                <br />Studio that will</h1>
                            <div className='hero-main-page-back-animate'>
                                <img src={MaiImage}></img>
                            </div>
                        </div>
                        <div className='company-about'>
                            <div className='mt-5 info justify-content-between d-sm-flex d-lg-flex'>
                                {/* <div className='row justify-content-center d-flex'> */}
                                <div className='StartUp '>
                                    <p>Design</p>
                                </div>
                                <div className='Enterprise '>
                                    <p>Develop</p>
                                </div>
                                <div className='media '>
                                    <p>Adverties</p>
                                </div>
                                <div className='social-good '>
                                    <p>Manage</p>
                                </div>
                                <span className='hero-page-product-for-you' style={{ marginLeft: "20px", color: "#98989A", paddingTop: "8px" }}>Products For You</span>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className='our-works'>
                            <div className='about-work-btn'>
                                <a href='#main-project-info' ><button className='about-service'>Our Works</button></a>
                            </div>
                            <div className=''>
                                <Link to={'/SubService'}><button className='about-service'> Our Service</button></Link>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='home-page-ser-main'>
                    <marquee>
                        <div className='home-page-service'>
                            <div className='flex items-center home-page-service-first'>
                                {/* <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div> */}
                                <div className='branding text-bold-etalic'>
                                    <h3><b style={{ fontWeight: "600" }}>BRANDING</b></h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-second'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='designing text-bold-etalic'>
                                    <h3><b style={{ fontWeight: "600" }}>DESIGNING</b></h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-third'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='flex items-center web-dev text-bold-etalic'>
                                    <h3><b style={{ fontWeight: "600" }}>WEB</b> DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-four text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='app-dev'>
                                    <h3><b style={{ fontWeight: "600" }}>ANDROID</b> APP DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='ios-app-dev'>
                                    <h3><b style={{ fontWeight: "600" }}>IOS</b> APP DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='blockchain-dev'>
                                    <h3><b style={{ fontWeight: "600" }}>BLOCKCHAIN</b> DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div className='flex items-center home-page-service-five text-bold-etalic'>
                                <div className='sun_icon'>
                                    <img src={SunIcon} />
                                </div>
                                <div className='adverties'>
                                    <h3><b style={{ fontWeight: "600" }}>SEO</b> AND <b style={{ fontWeight: "600" }}>ADVERTIES</b></h3>
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>

                

                <MainAbout />
                <ServicePage />
                <Industripage />
                <ChooseUs />
                <Projects />
                <ValuatedClient />
                <FAQsPage />

            </div>

        </div>
    )
}

export default Home
