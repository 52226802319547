import React, { useEffect } from 'react'
import Bulletpoint from '../assests/Update-Tapas-Img/bullet_point_icon.png'

const ServiceSubPage = () => {
    
    const designBrandItems = [
        {
            title: "Logo & Branding",
            description: "We craft logos and visual identities that resonate with audiences."
        },
        {
            title: "Graphic & Print Design",
            description: "We design print materials with attention to detail and brand consistency."
        },
        {
            title: "Web/App UI/UX Design",
            description: "We create user-friendly websites and app UIs with intuitive digital experiences."
        },
        {
            title: "Brand Strategy & Messaging",
            description: "We craft compelling stories and strategies to connect with customers."
        }
    ];
    const webAppDevItems = [
        {
            title: "Custom Web Design",
            description: "We specialize in crafting bespoke websites tailored to your unique brand identity and business goals."
        },
        {
            title: "E-commerce Solutions",
            description: "We create seamless online shopping experiences with secure payment gateways and intuitive product catalogs."
        },
        {
            title: "Native App Development",
            description: "We craft native apps for specific platforms like iOS or Android, offering optimized performance and user experience."
        },
        {
            title: "Custom App Development",
            description: "We build tailored mobile applications to meet your unique business needs and objectives."
        },
        {
            title: "API Development & Integration",
            description: "We seamlessly connect your systems and services with our custom-built APIs."
        },
        {
            title: "Cross-platform Development",
            description: "We reach a wider audience with mobile apps that run smoothly on both iOS and Android devices."
        },
        {
            title: "App Maintenance & Support",
            description: "We provide ongoing maintenance and support services to keep your app up-to-date, secure, and optimized for performance."
        },
        {
            title: "Testing & Quality Assurance",
            description: "We implement rigorous testing procedures to ensure app stability, functionality, and security across various devices and operating systems."
        },
        {
            title: "AR & VR Development",
            description: "We engage your audience with immersive augmented and virtual reality experiences."
        }
    ];
    const projectManageItems = [
        {
            title: "SEO",
            description: "We boost website visibility on search engines through strategic optimization, driving organic traffic and higher rankings."
        },
        {
            title: "Content Marketing",
            description: "We create engaging content to attract and retain customers, building brand authority and driving conversions."
        },
        {
            title: "Social Media Marketing",
            description: "We reach and engage your audience on platforms like Facebook and Instagram, driving traffic and brand awareness through targeted ads and compelling content."
        },
        {
            title: "Email Marketing",
            description: "We nurture leads and maintain customer relationships through personalized email campaigns, delivering valuable content and promotions directly to subscribers."
        },
        {
            title: "Analytics & Reporting",
            description: "We utilize data-driven insights to track performance, measure key metrics, and optimize strategies for improved results and ROI."
        }
    ];
    const seoMarketingItems = [
        {
            title: "IT Consulting",
            description: "We provide strategic guidance on IT infrastructure and digital transformation to align technology investments with business goals."
        },
        {
            title: "Cloud Computing",
            description: "We offer scalable and cost-effective cloud solutions (IaaS, PaaS, SaaS) for businesses seeking flexibility and efficiency."
        },
        {
            title: "Cybersecurity",
            description: "We protect businesses from threats with security assessments, risk management, and incident response services."
        },
        {
            title: "Software Development",
            description: "We outsource custom software projects for specialized expertise and cost-effective solutions."
        }
    ];
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);
    return (
        <div className='container mt-[10px]'>
            <div className='service-sub-main' id='about-main-service-page'>
                <div className='row'>
                    <div className='service-hero'>
                        <h2 className='text-[48px] font-semibold'>Our Services</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Transform your brand with our innovative digital solutions that captivate and engage your audience.</p>
                    </div>
                    <div className='service-sub-child'>
                        <div className='sub-design-brand mt-[30px]'>
                            <h5>Designing & branding</h5>
                            {designBrandItems.map((item, index) => (
                                <div key={item} className='flex items-center sub-design-brand-info d-flex'>
                                    <div>
                                        <img src={Bulletpoint} alt="" />
                                    </div>
                                    <div>
                                        <p className='text-[18px] text-[#999999] final-design-anime-first'>
                                            <span className='font-semibold text-white'>{item.title}:</span> {item.description}
                                        </p>
                                    </div>

                                </div>
                            ))}
                        </div>

                        <div className='sub-web-app-dev'>
                            <h5 >Web & App Development</h5>

                            {webAppDevItems.map((item, index) => (
                                <div key={item} className='flex items-center sub-web-dev-info d-flex'>
                                    <img src={Bulletpoint} alt="" />
                                    <p className='text-[18px] text-[#999999] final-web-dev-anime-first'>  <span className='font-semibold text-white'>{item.title}:</span> {item.description}</p>
                                </div>
                            ))}
                        </div>

                        <div className='sub-project-manage'>
                            <h5>SEO & Marketing</h5>
                            {projectManageItems.map((item, index) => (
                                <div key={item} className='flex items-center sub-project-manage-info d-flex'>
                                    <img src={Bulletpoint} alt="" />
                                    <p className='text-[18px] text-[#999999] final-project-manage-anime-first'><span className='font-semibold text-white'>{item.title}:</span> {item.description}</p>
                                </div>
                            ))}
                        </div>

                        <div className='sub-seo-marketing'>
                            <h5>We Also Provide:</h5>
                            {seoMarketingItems.map((item, index) => (
                                <div key={item} className='flex items-center sub-seo-marketing-info d-flex'>
                                    <img src={Bulletpoint} alt="" />
                                    <p className='text-[18px] text-[#999999] final-seo-marketing-anime-first'><span className='font-semibold text-white'>{item.title}:</span> {item.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSubPage
