import React from "react";

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="flex flex-wrap w-full faq-child item-center">
        <div className="faq-box"><span>{faq.box}</span></div>
        <div className="w-[90%]">
          <div className="faq-question"><p>{faq.question}</p></div>
          <div className="ml-[30px] faq-answer">{faq.answer}</div>
        </div>
   
      </div>
    </div>
  );
};

export default FAQ;
