import React from 'react'
import CommingSoon from '../assests/Update-Tapas-Img/final-comming-soon-icon.svg'
import Textile from '../assests/Update-Tapas-Img/final-textile.svg'
import TexAboutusIcon from '../assests/Update-Tapas-Img/final-about-us-icon.svg'
const data = [
    { id: 1, text: 'Inventory Management', description: 'Track raw materials, finished goods, and intermediate products in real-time.' },
    { id: 2, text: 'Production Planning and Scheduling', description: 'Optimize production activities and allocate resources efficiently.' },
    { id: 3, text: 'Quality Control', description: 'Implement quality assurance processes to maintain product quality standards.' },
    { id: 4, text: 'Supply Chain Management', description: 'Coordinate and optimize the flow of materials, information, and finances across the supply chain.' },
    { id: 5, text: 'Costing and Pricing', description: 'Calculate production costs accurately and determine optimal pricing strategies.' },
    { id: 6, text: 'Compliance and Regulatory Reporting', description: 'Ensure compliance with industry regulations and generate necessary reports.' },
    { id: 7, text: 'Business Intelligence and Analytics:', description: 'Gain insights into key performance indicators and make data-driven decisions.' }
];

const IndustrialSolutionsTab = () => {
    return (
        <div className='mt-[50px]'>
            <div className="container">
                <div className='flex final-industrialtab-main'>
                    <div className='w-[30%] pt-5  final-indusrial-textlite'>
                        <div className='flex flex-wrap mb-[50px] text-[18px]'>
                            <img className="mr-[20px]" src={CommingSoon} alt="" srcSet="" /> Textile
                        </div>
                        <div className='flex flex-wrap mb-[50px] text-[18px]'>
                            <img className="mr-[20px]" src={Textile} alt="" srcSet="" /> Comming Soon...
                        </div>
                    </div>
                    <div className='w-[80%] bg-[#191919] p-5 rounded-[25px] final-indusrial-textlite-info'>
                        <div className='text-white'>
                            <h3 className='text-[30px] font-semibold mb-[10px]'>
                                TextilePro ERP
                            </h3>
                            <div className='text-[#B3B3B3] text-[18px]'>
                                <span>
                                    #TextileERP
                                </span>
                                <span className='ml-4'>
                                    Your All-in-One Solution
                                </span>
                            </div>
                        </div>
                        <div className='text-white mt-[50px]'>
                            <h3 className='text-[24px] font-semibold mb-[10px]'>
                                About This Software
                            </h3>
                            <div className='text-[#B3B3B3] text-[18px]'>
                                TextilePro ERP is a comprehensive enterprise resource planning (ERP) solution designed specifically for businesses in the textile industry. It integrates a wide range of features to streamline operations, enhance efficiency, and drive growth for textile manufacturers, wholesalers, and retailers.
                            </div>
                        </div>
                        <div className='mt-[30px]'>
                            <h3 className='text-[24px] text-semibold mb-[15px]'>
                                Core Functionality Highlights
                            </h3>
                            <ul>
                                {data?.map(item => (
                                    <li key={item.id} className='my-3 text-[18px] flex'>
                                        <div className="w-6 h-6 mr-2 bg-center bg-cover"><img src={TexAboutusIcon}></img></div>
                                        <div className='text-[#B3B3B3] text-[18px]'>
                                            <span className='text-white text-[18px] font-medium'>{item.text} : </span> {item.description}
                                        </div>
                                      
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='mt-[50px]'>
                            {/* <button className='bg-[#ff5c00] rounded-lg p-3 text-[18px] text-light border-none block m-auto px-5'>
                                Learn More
                            </button> */}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default IndustrialSolutionsTab