import React from 'react'
import Logo from '../assests/Update-Tapas-Img/final-main-logo.png'
// import Facebook from '../assests/Update-Tapas-Img/Facebook.png'
import Twiter from '../assests/Update-Tapas-Img/Twiter.png'
import LinkDen from '../assests/Update-Tapas-Img/LinkDen.png'
import Instagram from '../assests/Update-Tapas-Img/final-insta-sec.svg'
import { Link } from 'react-router-dom'
import { IoIosSend } from "react-icons/io";


const Footer = () => {
    const today = new Date();
    const year=today.getFullYear();
    
    return (
        <div className='container'>
            <div className='row'>
                <div class="footer-hero-head">
                    {/* <div className='row'> */}
                    <div class="footer-logo-sec">
                        <div className='logo2'>
                            <img src={Logo} alt="Logo" /><br />
                        </div>
                        <div className='final-footer-contant-main'>
                            <ul>
                                <Link to={'/'}><li>Home</li></Link>
                                <Link to='/SubService'>
                                    <li>Services</li>
                                </Link>
                                <Link to={'/about'}>
                                    <li>About</li>
                                </Link>
                                <Link to={'/Industrial-Solutions'}>
                                    <li>Industry Software</li>
                                </Link>
                                <Link to={'/contact'}><li>Contact Us</li></Link>

                            </ul>
                        </div>
                        <div className='final-footer-get-mail'>
                            <form className='pt-0 final-footer-form-main'>
                                <input className='email-input' type='email' name='Mail' required placeholder='Send us e-mail' />
                                <button className='send-icon-btn'><IoIosSend className='send-icon' /></button>
                            </form>
                        </div>
                        
                    </div>
                    {/* <div className='footer-con-first'>

                        <div class="menu-footer" id='navbar'>
                            <ul>
                                <Link className='footer-home'>
                                    <li>Home</li>
                                </Link>
                                <a >
                                    <li>About Us</li>
                                </a>
                                <a >
                                    <li>Features</li>
                                </a>
                                <a >
                                    <li>Properties</li>
                                </a>
                                <a>
                                    <li>Testimonals</li>
                                </a>
                                <a>
                                    <li>FAQ's</li>
                        
                                </a>
                            </ul>
                        </div>
                        <div className='About-us-menu'>
                            <ul>
                                <a className='about-footer'>
                                    <li>About Us</li>
                                </a>
                                <a>
                                    <li>Our Story</li>
                                </a>
                                <a>
                                    <li>Our Works</li>
                                </a>
                                <a>
                                    <li>How It Works</li>
                                </a>
                                <a>
                                    <li>Our Team</li>
                                </a>
                                <a>
                                    <li>Our Clients</li>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='footer-con-second'>
                        <div className='Service-menu'>
                            <ul>
                                <a className='service-footer'>
                                    <li>Services</li>
                                </a>
                                <a>
                                    <li>Valuation Mastery</li>
                                </a>
                                <a>
                                    <li>Strategic Marketing</li>
                                </a>
                                <a>
                                    <li>Negotiation Wizardry</li>
                                </a>
                                <a>
                                    <li>Closing Success</li>
                                </a>
                                <a>
                                    <li>Property Management</li>
                                </a>
                            </ul>
                        </div>
                        <div className='Contact-menu'>
                            <ul>
                                <a className='content-footer'>
                                    <li>Contact Us</li>
                                </a>
                                <a>
                                    <li>
                                        Contact Form
                                    </li>
                                </a>
                                <a>
                                    <li>
                                        Our Offices
                                    </li>
                                </a>
                            </ul>
                        </div>
                    </div> */}

                    <div class="">
                        {/* <div className='footer-content-right'>
                        <div className='stay-connect'>
                            <p>Stay Connected</p>
                        </div>
                        <div className='footer-social-media'>
                            <div className='facebook'>
                                <img src={Facebook} alt='Facebook Logo' />
                            </div>
                            <div className='Twiter'>
                                <img src={Twiter} alt='Twitter Logo' />
                            </div>
                            <div className='LinkDen'>
                                <img src={LinkDen} alt='LinkDen Logo' />
                            </div>
                        </div>
                    </div> */}


                        {/* </div> */}
                    </div>
                </div>
                <div className='footer-child'>
                    <div className='footer-last-content'>
                        <div className='company-social-media'>
                            <a href='https://www.linkedin.com/company/tapas-technology-private-limited/' target='blank'><img src={LinkDen} alt='Linkedin Icon' /></a>
                            <a href='https://twitter.com/TapasTechnology' target='blank'><img src={Twiter} style={{ marginLeft: "20px" }} alt='Twiter Icon' /></a>
                            <a href='https://www.instagram.com/tapas_technology/' target='blank'><img src={Instagram} style={{ marginLeft: "20px" }} alt='Instagram Icon' /></a>
                        </div>
                        <div className='reseved-section'>
                            <p>&#169;  {year} Tapas Technology PVT. LTD. All Rights Reserved.</p>
                        </div>
                        <div className='trems-condition-part'>
                        <Link to={'/TermsAndCondition'} className='footer-terms-condition'>Terms & Conditions</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
