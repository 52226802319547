import React from 'react'
import NFTLanding from '../assests/Update-Tapas-Img/nft-landing.png'
import MonogazeLanding from '../assests/Update-Tapas-Img/monogaze-landing.png'
import StreamingLanding from '../assests/Update-Tapas-Img/streaming-landing.png'
import WorkoutApp from '../assests/Update-Tapas-Img/work_out_app_ss.png'
import FoodApp from '../assests/Update-Tapas-Img/food_app_ss.png'
import HealthApp from '../assests/Update-Tapas-Img/health_app_ss.png'
import FinanceApp from '../assests/Update-Tapas-Img/financh-app-ss.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules'

const Projects = () => {
    const calculateSlidesPerView = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 450) {
            return 1; // Show 1 slide on smaller screens

        } else if (screenWidth < 900) {
            return 2; // Show 2 slides on medium-sized screens
        }
        else  {
            return 3; // Show 2 slides on medium-sized screens
        } 
        
    };
    return (
        <div className='container'>
            <div className='project-main'>
                <div className='row'>
                    <div className='hero-projects' id='main-project-info'>
                        <h2 className='text-[48px] font-semibold'>Our Projects</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Experience excellence in digital craftsmanship with our team of skilled professionals dedicated to delivering exceptional results.</p>
                    </div>
                    <div className='projects-child'>
                        <Swiper
                            pagination={{
                                // type: 'progressbar',
                            }}
                            navigation={true}
                            loop={true}
                            spaceBetween={'20px'}
                            slidesPerView={calculateSlidesPerView()}
                            modules={[ Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={NFTLanding} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>NFT Landing Page</p>
                                            <p className='release-date'>March 2023</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={MonogazeLanding} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Monogaze Landing Page</p>
                                            <p className='release-date'>January 2023</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={StreamingLanding} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Streaming Platform</p>
                                            <p className='release-date'>January 2022</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={WorkoutApp} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Workout App</p>
                                            <p className='release-date'>February 2023</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={FoodApp} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Food App</p>
                                            <p className='release-date'>September 2023</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={HealthApp} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Health Care App</p>
                                            <p className='release-date'>August 2022</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-project'>
                                    <div className='project-landing-img'>
                                        <img src={FinanceApp} />
                                    </div>
                                    <div className='first-pro-info'>
                                        <div className='nft-pro-info'>
                                            <p className='landing-1'>Fintech App</p>
                                            <p className='release-date'>April 2023</p>
                                        </div>
                                        <div className='view-nft'>
                                            <p>VIEW PROJECTS <MdOutlineArrowOutward color='white' fontSize={'20px'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Projects
