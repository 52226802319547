import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import './Media.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter and related components
import HomePage from './Component/Home';
import AboutUs from './Component/AboutUs';
import ServicePage from './Component/Service';
import ServiceSubPage from './Component/ServiceSubPage';
import TermsAndCondition from './Component/TermsCondition';
import OurTeam from './Component/Ourteam'
// import IndustrialSolutions from './Component/IndustrialSolutions';

import Layout from './Layout/Layout';
import IndustrialSolutions from './Component/IndustrialSolutions';
import Contact from './Component/Contact';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Service" element={<ServicePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/SubService" element={<ServiceSubPage />} />
          <Route path="/Industrial-Solutions" element={<IndustrialSolutions />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        </Routes>
      </Layout>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
