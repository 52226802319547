import React from 'react'
import Twiter from '../assests/Update-Tapas-Img/Twiter.png'
import ClientImg from '../assests/Update-Tapas-Img/client-img.png'
import ClientImg2 from '../assests/Update-Tapas-Img/client-sec.png'
import ClientImg3 from '../assests/Update-Tapas-Img/client-img-third.png'
import ClientImg4 from '../assests/Update-Tapas-Img/client-four.png'
import ClientImg5 from '../assests/Update-Tapas-Img/client-fifth.png'
import ClientImg6 from '../assests/Update-Tapas-Img/client-six.png'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ValuatedClient = () => {
    const calculateSlidesPerView = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 450) {
            return 1; // Show 1 slide on smaller screens

        } else if (screenWidth < 900) {
            return 2; // Show 2 slides on medium-sized screens
        }
        else  {
            return 3; // Show 3 slides on large-sized screens
        } 
    };
    return (
        <div className='container'>
            <div className='client-main'>
                <div className='row'>
                    <div className='hero-client'>
                        <h2 className='text-[48px] font-semibold'>Our Valued Clients</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider w-[80%] text-center m-auto'>At Tapas, we have had the privilege of working with a diverse range of clients across various industries. Here are some of the clients we've had the pleasure of serving</p>
                    </div>
                    <div className='client-child'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                // clickable: true,
                            }}
                            loop={true}
                            slidesPerView={calculateSlidesPerView()}
                            // navigation={true}
                            modules={[Autoplay, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>
                                            Our outdated website was holding us back. Tapas modernized our online presence and streamlined our customer experience. Sales have increased significantly!
                                            </p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>Sarah Wilson</p>
                                            <p className='client-post'>Director of Marketing (Acme Corp)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>The Tapas team felt like an extension of our own. They listened to our needs and delivered a custom solution that has made our entire IT department more efficient.</p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg2} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>Alex Patel</p>
                                            <p className='client-post'> IT Manager (Horizon Industries)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>We needed a secure patient portal fast. Tapas exceeded our expectations, delivering a user-friendly system in record time."</p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg3} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>Dr. Emily Chen</p>
                                            <p className='client-post'>Owner (Riverside Medical Clinic)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>Tapas's technical knowledge is impressive. They seamlessly integrated complex features into our website, taking our design vision to the next level.</p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg4} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>Michael Jones</p>
                                            <p className='client-post'>Creative Director (Bold Branding Agency)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>Tapas isn't just about building websites, they're about partnership. Their ongoing support ensures our online store is always running smoothly.</p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg5} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>Olivia Baker</p>
                                            <p className='client-post'>E-commerce Manager (Trendy Threads Boutique)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='first-client'>
                                    <div className='first-client-info'>
                                        <div className='client-information'>
                                            {/* <img src={Twiter} alt='Twiter Icon' /> */}
                                            <p>Since partnering with Tapas, our online lead generation has skyrocketed. Their data-driven approach has been a game-charger for our business.</p>
                                        </div>
                                    </div>
                                    <div className='first-client-personal-info'>
                                        <div className='client-img'>
                                            <img src={ClientImg6} />
                                        </div>
                                        <div className='client-name'>
                                            <p className='name'>David Lee</p>
                                            <p className='client-post'>CEO (StartUp Solutions Inc.)</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
                        </Swiper>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ValuatedClient
