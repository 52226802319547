import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';
import Movetxt from './Movetxt';

const Layout = ({ children }) => {
    return (
        <>
            <Navbar />
            {children}
            <Movetxt />
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;