import React from 'react'
import AnimateTapas from '../assests/Update-Tapas-Img/mainabout-img.svg'

import MainAnime from '../assests/Update-Tapas-Img/final-full-page-back-anime.gif'
import CountUp from "react-countup";
import { Link } from 'react-router-dom';

const MainAbout = () => {
    return (
        <div>
            <div className='row'>
                <div className='about-us-main '>
                    <div className='about-us-child '>
                        <div className='about-us-hero'>
                            <h2>Discover Your Dream <br /> Property With Estatein</h2>
                            <p>Your journey to finding the perfect property begins here. Explore our listings to find the home that matches your dreams.</p>
                            {/* <div className='about-us-button'>
                                <a href='#about-main-service-page'><Link to={'/SubService'}><button className='about-service'> Our Service</button></Link></a>
                                <button className='expretise'>Our Expertise</button>
                            </div> */}
                        </div>
                        <div className='about-us-counter d-flex justify-content-between'>
                            <div className='happy-client'>
                                <h2><CountUp end={800} duration={3} enableScrollSpy={true}></CountUp>+</h2>
                                <p>Happy Client</p>
                            </div>
                            <div className='properti-for-client'>
                                <h2><CountUp end={1200} duration={3} enableScrollSpy={true}></CountUp>+</h2>
                                <p>Project Completed</p>
                            </div>
                            <div className='Experince'>
                                <h2><CountUp end={500} duration={3} enableScrollSpy={true}></CountUp>+</h2>
                                <p>Website Developed</p>
                            </div>
                        </div>
                    </div>
                    <div className='about-us-tapas-animate'>
                        <img src={AnimateTapas} alt='' />
                        <div className='full-page-animation'>
                            <img src={MainAnime}></img>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainAbout
