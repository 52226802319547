import React, { useEffect, useState } from 'react'
import MultiRangeSlider from './MultiRangeSlider'
import FAQ from './FAQ';
import axios from 'axios';
import Swal from "sweetalert2";
import FAQsPage from './FAQsPage';
import ContactusIcon from '../assests/Update-Tapas-Img/final-final-contact-us-icon.svg';

const Contact = () => {
    // const [name, setName] = useState();
    // const [email, setEmail] = useState();
    // const [service, setService] = useState();
    // const [message, setMessage] = useState();
    // const [budget, setBudget] = useState();
    // const [loading, setLoading] = useState(false);

    // const handlesubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     const date = new Date().toDateString();
    //     const API = process.env.REACT_APP_API_URL;
    //     let data = {
    //         "date": date,
    //         "event_name": "Contact",
    //         "name": name,
    //         "email": email,
    //         "service": service,
    //         "message": message,
    //         "budget": budget,
    //         "ref": "Contact us"
    //     }

    //     try {
    //             await axios.post(API, data,
    //                 {
    //                     headers: {
    //                         'Content-Type': "text/plain;charset=utf-8"
    //                     },
    //                     'mode': "no-cors"
    //                 }).then((resp) => {
    //                     if (resp.status === 200) {

    //                         setLoading(false);
    //                         setName('');
    //                         setEmail('');
    //                         setService('');
    //                         setMessage('');
    //                         setBudget('');
    //                         Swal.fire(
    //                             'Thank You For Inquiry , we will contact You soon on Whatsapp',
    //                             'Your form is succesfully submitted!',
    //                             'success'
    //                         )
    //                     }


    //                 }).catch(error => {
    //                     console.log("ERROR", error);
    //                     setLoading(false);
    //                     Swal.fire(
    //                         "Something went wrong",
    //                         "Try after few minutes",
    //                         "error"
    //                     );

    //                 });
    //     } catch (error) {
    //         setLoading(false);

    //         Swal.fire(error.message, "Something went wrong", "error");
    //     }
    // };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);
    return (
        <div>
            <div className="container mb-5 mt-5">
                <div className='pt-5 text-center final-contact-hero-section'>
                    <h1 className='text-[48px] font-semibold'>
                        Contact Us
                    </h1>
                    <p className='text-[18px] leading-7 mt-3 tracking-wider'>
                        Get in touch with us today and let us help you with any questions or inquiries you may have.
                    </p>
                </div>
                <div className=' p-4 final-contact-company-info-main'>

                    <div >
                        <div className='flex w-[90%] final-contact-us-main  m-auto justify-between final-contact-company-info-main-sec'>
                            <div className='flex bg-[#262626] p-3  rounded-lg items-center final-contact-company-info'>
                                <img className="mr-[15px] " src="/img/subtract.png" alt="" srcSet="" />
                                <a href='mailto:tapastechnologypvtltd@gmail.com' style={{ textDecoration: "none", color: "white" }}>tapastechnologypvtltd@gmail.com</a>
                            </div>
                            <div className='flex bg-[#262626] p-3  rounded-lg items-center final-contact-company-info'>
                                <img className="mr-[15px]" src={ContactusIcon} alt="" srcSet="" />
                                <a href='tel:91 8140233244' style={{ textDecoration: "none", color: "white" }}>+91 8140 233 244</a>
                            </div>
                            <div className='flex bg-[#262626] p-3 rounded-lg items-center final-contact-company-info'>
                                <img className="mr-[15px]" src="/img/location-icon.png" alt="" srcSet="" />
                                <a style={{ textDecoration: "none", color: "white" }} href='https://maps.app.goo.gl/udYzm9j3KddJYDgV6' target='blank' ><p>Get Location</p></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-[#141414] pb-5'>
                    <form className="w-[70%] m-auto pb-5 final-contact-user-details-form">
                        <div className='flex flex-wrap items-center justify-content-between final-contact-about-user'>
                            <div className="mb-4 w-[48%] p-4 px-4 mt-5 bg-[#262626] rounded-[20px] final-contact-user-name">
                                <label htmlFor="fullname" className="block mb-3">Full Name:</label>
                                {/* <input type="text" id="fullname" name="fullname" required onChange={(e) => setName(e.target.value)} value={name} className="w-full bg-[#262626] border-solid border-b-2 border-[#656567] text-white focus-visible:outline-none final-contact-input-main" */}
                                <input type="text" id="fullname" name="fullname" required  className="w-full bg-[#262626] border-solid border-b-2 border-[#656567] text-white focus-visible:outline-none final-contact-input-main"
                                />
                            </div>
                            <div className="mb-4 w-[48%] p-4 px-4 mt-5 bg-[#262626] rounded-[20px] final-contact-user-mail">
                                <label htmlFor="email" className="block mb-3">Email:</label>
                                {/* <input type="email" id="email" name="email" required onChange={(e) => setEmail(e.target.value)} value={email} className="w-full bg-[#262626] border-solid border-b-2 border-[#656567] text-white focus-visible:outline-none final-contact-input-main" /> */}
                                <input type="email" id="email" name="email" required  className="w-full bg-[#262626] border-solid border-b-2 border-[#656567] text-white focus-visible:outline-none final-contact-input-main" />
                            </div>
                        </div>

                        <div className="mb-4 w-full p-4 px-4 mt-5 bg-[#262626] rounded-[20px] final-contact-info-main-service">
                            <label className="block mb-5" name="reason" htmlFor="checkbox1">Why are you contacting us? <span className="text-gray-600"></span></label>
                            <div className="flex items-center final-contact-user-selection">
                                <div className='w-[50%] final-contact-user-requiment'>
                                    <label htmlFor="checkbox1" className="mb-4 option">Web Design and Development<input type="checkbox" id="checkbox1" name="checkbox1" />
                                        <span className="checkbox checkbox1"></span>
                                    </label>
                                    {/* <label className="form-control">
                                        <input type="checkbox" name="checkbox" />
                                        Checkbox
                                    </label> */}
                                    <label htmlFor="checkbox2" className="mb-4 option">Mobile App  and Development<input type="checkbox" id="checkbox2" name="checkbox2" />
                                        <span className="checkbox checkbox1"></span>
                                    </label>
                                </div>
                                <div className='w-[50%] final-contact-user-requiment'>
                                    <label htmlFor="checkbox3" className="mb-4 option">Industrial Software related<input type="checkbox" id="checkbox3" name="checkbox3" />
                                        <span className="checkbox checkbox1"></span>
                                    </label>
                                    <label htmlFor="checkbox4" className="mb-4 option">Others<input type="checkbox" id="checkbox4" name="checkbox4" />
                                        <span className="checkbox checkbox1"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-full d-block h-[10rem] mt-5">
                            <label htmlFor="budget" className="block mb-1">Your Budget:</label>
                            {/* <MultiRangeSlider
                                min={0}
                                max={100000}
                                // onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
                                onChange={(e) => setBudget(e.min)} value={budget}
                            /> */}
                            <MultiRangeSlider
                                min={0}
                                max={100000}
                                onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
                            />


                        </div>
                        <div className="w-full pb-10 my-3">
                            <div className='mt-10 rounded-[20px] w-full'>

                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                                {/* <textarea id="message" rows="4" onChange={(e) => setMessage(e.target.value)} value={message} className="block p-3 w-full text-sm text-[#656567] bg-[#262626] rounded-lg focus:ring-blue-500 focus:border-#ff5c00-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-#ff5c00-500 focus-visible:outline-none" placeholder="Write your thoughts here..."></textarea> */}
                                <textarea id="message" rows="4"  className="block p-3 w-full text-sm text-[#656567] bg-[#262626] rounded-lg focus:ring-blue-500 focus:border-#ff5c00-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-#ff5c00-500 focus-visible:outline-none" placeholder="Write your thoughts here..."></textarea>
                            </div>
                        </div>
                        <button className='bg-[#FF5C00] rounded-lg final-contact-data-submit text-[18px] block m-auto text-light border-none' type='submit'>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
            <FAQsPage />
        </div>
    )
}

export default Contact