import React, { useEffect } from 'react'
import IndustrialSolutionsTab from './IndustrialSolutionsTab'
import { Link } from 'react-router-dom'

const IndustrialSolutions = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[]);
    return (
        <div>
            <div className="container">
                <div className='relative mt-[10px] final-industrial-main-info'>
                    <div className=''>
                        <img src="/img/solutions.png" className='indusrial-final-main-img-sec' alt="" srcSet="" />
                        <img src="/img/final-main-ind.png" className='indusrial-final-main-img' alt="" srcSet="" />
                    </div>
                    <div className='w-[70%] text-[#797C86] absolute top-[0px] pl-[50px] final-indtrial-hero-main'>
                        <h3 className='text-[16px] uppercase mt-[20px]'>
                            Software Solutions
                        </h3>
                        <h1 className='text-[48px] font-semibold text-white uppercase tracking-[2px] final-industrial-hero-info-txt'>
                            Tapas’s <span className='text-[#FF5C00]'>industrial solutions</span>
                        </h1>
                        <p className='text-[18px] leading-relaxed mt-[10px] final-industrial-hero-info-txt-sec'>
                            Step into a visual journey that encapsulates the essence of my lens. Each photograph in this portfolio is a narrative, a frozen moment in time, and a testament to the artistry and passion poured into every frame. Explore the diverse tapestry of stories I've had the privilege to capture and witness the world through my lens.
                        </p>
                    </div>
                    <div className='absolute left-10 bottom-0 final-industrial-hero-child-img'>
                        <img src="./img/abstract-design.png" alt="" srcSet="" />
                    </div>
                    {/* <div className='absolute right-10 bottom-10 final-industrial-hero-child-img'>
                        <img className="w-[100%]" src="./img/Text.png" alt="" srcSet="" />
                    </div> */}
                </div>
                <div className='p-5 bg-[#191919] flex flex-wrap mt-5 justify-between items-center border-1 border-[#262626] rounded-[12px] relative final-indusrial-main'>
                    <div className='p-4 final-industrial-txt'>
                        <h1 className='Tailored E-commerce Solutions text-[40px] '>
                            Tailored  <span className='text-[#FF5C00] font-semibold tracking-[2px]'>E-commerce </span> Solutions
                        </h1>
                        <p className='text-[18px] text-[#B3B3B3] mt-[10px]'>
                            Your Online Success Starts Here: Customized E-commerce Solutions by Tapas.
                        </p>
                    </div>
                    
                    <div className='finaly-contact-btn'>
                        <Link to={'/contact'}><button type="submit" className='bg-[#ff5c00] rounded-lg  text-[18px] text-light border-none  block'>Contact Us</button></Link>
                    </div>
                </div>
                <IndustrialSolutionsTab />
            </div>
        </div>

    )
}

export default IndustrialSolutions