import React, { useEffect } from 'react'

const TermsCondition = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <div className='container mt-[10px]'>
            <div className='row'>
                <div className='service-hero'>
                    <h2 className='text-[48px] font-semibold'>Agency Terms of Service</h2>
                    {/* <p className='text-[18px] leading-7 mt-3 tracking-wider'>Transform your brand with our innovative digital solutions that captivate and engage your audience.</p> */}
                </div>
                <div className='main-terms-condition'>

                    <div className='child-terms-condition'>
                        <h3>Tapas Technology PVT. LTD.</h3>
                    </div>
                    <div className='ters-about-info'>
                        <div className="terms-introduction">
                            <h6 className="linear-wipe">Introduction</h6>
                            <p>These Terms of Service ("TOS") govern the relationship between Tapas Technology PVT. LTD. ("Tapas," "we" or "us") and its clients ("Client" or "you") regarding the provision of digital solutions services. These services include but are not limited to:</p>
                            <ul>
                                <li>Software development</li>
                                <li>Website development</li>
                                <li>App development</li>
                                <li>Other custom digital solutions</li>
                            </ul>
                            <p>By engaging with Tapas, you agree to be bound by these TOS. Please read them carefully.</p>
                        </div>
                        <div className='terms-defination'>
                            <h6 className="linear-wipe">Definitions</h6>
                            <ul>
                                <li>"Deliverables": The final work products provided by Tapas under a specific agreement with a Client.</li>
                                <li>"Intellectual Property": Includes patents, trademarks, copyrights, trade secrets, design rights, and other proprietary rights.</li>
                                <li>"Services": The digital solutions offerings provided by Tapas.</li>
                            </ul>
                        </div>
                        <div className='terms-services'>
                            <h6 className="linear-wipe">Services Provided</h6>
                            <ul>
                                <li>Tapas provides a range of customized digital solutions tailored to meet our Clients' specific needs. Our detailed scope of work will be defined within individual project agreements.</li>
                            </ul>
                        </div>
                        <div className='trems-company-limitation'>
                            <h6 className="linear-wipe">Limitations and Exclusions</h6>
                            <ul>
                                <li>Tapas is not responsible for issues arising from Client-provided content or third-party integrations not directly managed by Tapas.</li>
                                <li>Tapas does not offer ongoing maintenance or support for projects unless specified within a separate agreement.</li>
                            </ul>
                        </div>
                        <div className='trems-payment'>
                            <h6 className="linear-wipe">Payment Terms</h6>
                            <ul>
                                <li>Payment Methods: We accept any payment methods.</li>
                                <li>Billing Frequency: Billing will occur [monthly, upon project milestones, etc.] as outlined in the project agreement.</li>
                                <li>Late Payments: Late payments may incur penalties as defined in the project agreement.</li>
                            </ul>
                        </div>
                        <div className='trems-Intellectual'>
                            <h6 className="linear-wipe">Intellectual Property Rights</h6>
                            <ul>
                                <li>Tapas retains ownership of all pre-existing Intellectual Property brought into the project.</li>
                                <li>Upon full payment, Clients receive a non-exclusive, [perpetual OR limited-term] license to use the Intellectual Property embodied in the final Deliverables for their intended business use.</li>
                                <li>Clients may not resell, redistribute, or sublicense Deliverables without Tapas's written consent.</li>
                            </ul>
                        </div>
                        <div className='trems-Confidentiality'>
                            <h6 className="linear-wipe">Confidentiality</h6>
                            <ul>
                                <li>Both parties agree to maintain the confidentiality of sensitive information exchanged during the project.</li>
                                <li>The use of Non-Disclosure Agreements (NDAs) may be required and will be outlined in the project agreement.</li>
                            </ul>
                        </div>
                        <div className='trems-Indemnity'>
                            <h6 className="linear-wipe">Liability and Indemnity</h6>
                            <ul>
                                <li>Tapas's liability is limited to the fees paid by the Client for the specific project.</li>
                                <li>The Client agrees to indemnify and hold Tapas harmless from any third-party claims arising from Client-provided content or Client's use of the Deliverables.</li>
                            </ul>
                        </div>
                        <div className="trems-Resolution">
                            <h6 className="linear-wipe">Dispute Resolution</h6>
                            <ul>
                                <li>Disputes shall be resolved first through good-faith negotiation. If unsuccessful, [mediation OR arbitration] will be used in [city, state].</li>
                            </ul>
                        </div>
                        <div className="trems-Law">
                            <h6 className="linear-wipe">Governing Law</h6>
                            <ul>
                                <li>This TOS is governed by the laws of Surat, India.</li>
                            </ul>
                        </div>
                        <div className='trems-Modification'>
                            <h6 className="linear-wipe">Modification of Terms</h6>
                            <ul>
                                <li>Tapas reserves the right to modify these TOS. Clients will be notified of significant changes</li>
                            </ul>
                        </div>
                        <div className="trems-Severability">
                            <h6 className="linear-wipe">Severability</h6>
                            <ul>
                                <li>If any part of this TOS is deemed invalid, the remainder shall remain in full force and effect.</li>
                            </ul>
                        </div>
                        <div className="trems-Majeure">
                            <ul>
                                <li>Neither party is liable for delays or failures caused by events beyond their reasonable control (e.g., natural disasters, acts of government).</li>
                            </ul>
                        </div>
                        <div className='trems-contact'>
                            <h6 className="linear-wipe">Contact Information</h6>
                            <p>For questions or concerns regarding these TOS,</p>
                            <p>please contact : <a href='mailto:tapastechnologypvtltd@gmail.com'>tapastechnologypvtltd@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition
