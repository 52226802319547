import React from 'react'

const Whychooseus = () => {
    return (
        <div className='container'>
            <div className='choose-us-main'>
                <div className='row'>
                    <div className='choose-us-hero'>
                        <h2 className='text-[48px] font-semibold'>Why Choose Tapas?</h2>
                        <p className='text-[18px] leading-7 mt-3 tracking-wider'>Experience excellence in digital craftsmanship with our team of skilled professionals dedicated to delivering exceptional results.</p>
                    </div>
                    <div className='choose-us-child'>
                        <div className='company-steps-part-first'>
                            <div className='company-first-step'>
                                <div className='step-1'>
                                    <p>Step 01</p>
                                </div>
                                <div className='step1-info'>
                                    <div className='info-1-heading'>
                                        <h4>Discovering Phase:</h4>
                                        <ul>
                                            <li>Understanding client needs.</li>
                                            <li>Researching and analyzing.</li>
                                            <li>Defining project scope.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='company-second-step'>
                                <div className='step-2'>
                                    <p>Step 02</p>
                                </div>
                                <div className='step2-info'>
                                    <div className='info-2-heading'>
                                        <h4>Strategic Planning:</h4>
                                        <ul>
                                            <li>Tailoring strategy to objectives.</li>
                                            <li>Setting milestones and KPIs.</li>
                                            <li>Establishing project timeline.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='company-third-step'>
                                <div className='step-3'>
                                    <p>Step 03</p>
                                </div>
                                <div className='step3-info'>
                                    <div className='info-2-heading'>
                                        <h4>Creative Design:</h4>
                                        <ul>
                                            <li>Using innovative design techniques.</li>
                                            <li>Creating user-friendly interfaces.</li>
                                            <li>Iterating design for perfection.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='company-fourth-step'>
                                <div className='step-4'>
                                    <p>Step 04</p>
                                </div>
                                <div className='step4-info'>
                                    <div className='info-4-heading'>
                                        <h4>Development and Implementation:</h4>
                                        <ul>
                                            <li>Employing cutting-edge technology.</li>
                                            <li>Ensuring functionality across devices.</li>
                                            <li>Conducting rigorous testing.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='company-fifth-step'>
                                <div className='step-5'>
                                    <p>Step 05</p>
                                </div>
                                <div className='step5-info'>
                                    <div className='info-5-heading'>
                                        <h4>Strategic Advertising:</h4>                                        
                                        <ul>
                                            <li>Utilizing targeted advertising strategies.</li>
                                            <li>Conducting market research and analysis.</li>
                                            <li>Crafting compelling ad campaigns.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='company-six-step'>
                                <div className='step-6'>
                                    <p>Step 06</p>
                                </div>
                                <div className='step6-info'>
                                    <div className='info-6-heading'>
                                        <h4>Optimization and Maintenance:</h4>                                                                        
                                        <ul>
                                            <li>Providing ongoing support.</li>
                                            <li>Monitoring performance metrics.</li>
                                            <li>Implementing updates promptly.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whychooseus
