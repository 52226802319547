import Logo from '../assests/Update-Tapas-Img/final-main-logo.png'
import { Link } from 'react-router-dom'
import NavToggle from '../assests/Update-Tapas-Img/final-navbar-toggle.svg'



const Navbar = () => {
    return (
        <div className='container'>
            <div className='row d-flex justify-content-around'>
                <div className="hero-head ">


                    {/* <nav className="navbar  text-light">

                        <p className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </p>
                        <div className=" " id="collapsibleNavbar">

                            <ul className="navbar-nav">
                                <Link to={'/'} >
                                    <li >Home</li>
                                </Link>
                                <Link to={'/about'}>
                                    <li>About</li>
                                </Link>
                                <Link to='/SubService'>
                                    <li>Services</li>
                                </Link>
                                <Link to={'/Industrial-Solutions'}>
                                    <li>Textile Industry Software</li>
                                </Link>
                            </ul>
                            

                        </div>
                    </nav> */}
                    <nav class="navbar navbar-expand-sm w-[84%] justify-content-between navbar-dark ">
                        <div className="logo ">
                            <img src={Logo} alt="Logo" />
                        </div>
                        <p class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            {/* <span class="navbar-toggler-icon"></span> */}
                            <img src={NavToggle}></img>
                        </p>
                        <div class="collapse navbar-collapse " id="collapsibleNavbar">

                            <ul class="navbar-nav ">
                                <Link to={'/'} >
                                    <li >Home</li>
                                </Link>
                                <Link to='/SubService'>
                                    <li>Services</li>
                                </Link>
                                <Link to={'/about'}>
                                    <li>About</li>
                                </Link>
                                <Link to={'/Industrial-Solutions'}>
                                    <li>Industry Software</li>
                                </Link>
                                <Link to={'/our-team'}>
                                    <li>Our Team</li>
                                </Link>
                            </ul>


                        </div>
                    </nav>
                        <div className="button w-[15%]">
                            <Link to={'/contact'}><button type="submit">Contact Us</button></Link>
                        </div>

                    {/* <div className='responsive-navbar' onClick={toggleNavbar}>
                    <DrawerComponent />
                    <i className="fa-solid fa-bars"></i>
                </div> */}
                </div>




            </div>
        </div>
    )
}

export default Navbar
